// TODO: move colours into theme
const getButtonStyles = (props) => ({
    bySizes: {
        small: {
            fontSize: '14px',
            lineHeight: '20px',
            minHeight: '32px',
            maxHeight: '32px',
            gap: '6px',
            borderRadius: '4px',
            padding: '6px 10px',
        },
        medium: {
            fontSize: '14px',
            lineHeight: '20px',
            minHeight: '40px',
            maxHeight: '40px',
            gap: '6px',
            borderRadius: '6px',
            padding: '10px 12px',
        },
        large: {
            fontSize: '16px',
            lineHeight: '24px',
            minHeight: '48px',
            maxHeight: '48px',
            gap: '8px',
            borderRadius: '6px',
            padding: '12px 16px',
        },
    },
    byVariants: {
        filled: {
            primary: {
                color: '#fff',
                backgroundColor: props.theme.palette.primary.main,
                backgroundColorOnHover: props.theme.palette.primary.dark,
                backgroundColorOnFocus: props.theme.palette.primary.main,
                backgroundColorOnLoading: props.theme.palette.primary.main,
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: `0px 0px 0px 3px ${props.theme.customColors.button.activeShadowPrimary}`,
                boxShadowOnFocus: `0px 0px 0px 3px ${props.theme.customColors.button.activeShadowPrimary}`,
                textDecorationOnActive: undefined,
            },
            'primary-light': {
                color: '#fff',
                backgroundColor: props.theme.palette.primary.main,
                backgroundColorOnHover: props.theme.palette.primary.dark,
                backgroundColorOnFocus: props.theme.palette.primary.main,
                backgroundColorOnLoading: props.theme.palette.primary.main,
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: `0px 0px 0px 3px ${props.theme.customColors.button.activeShadowPrimary}`,
                boxShadowOnFocus: `0px 0px 0px 3px ${props.theme.customColors.button.activeShadowPrimary}`,
                textDecorationOnActive: undefined,
            },
            error: {
                color: '#fff',
                backgroundColor: props.theme.palette.error.main,
                backgroundColorOnHover: props.theme.palette.error.dark,
                backgroundColorOnFocus: props.theme.palette.error.main,
                backgroundColorOnLoading: props.theme.palette.error.main,
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
                textDecorationOnActive: undefined,
            },
            success: {
                color: '#fff',
                backgroundColor: props.theme.palette.success.main,
                backgroundColorOnHover: '#248A59',
                backgroundColorOnFocus: props.theme.palette.success.main,
                backgroundColorOnLoading: props.theme.palette.success.main,
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: '0px 0px 0px 3px rgba(48, 164, 108, 0.20)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(48, 164, 108, 0.20)',
                textDecorationOnActive: undefined,
            },
            warning: {
                color: '#fff',
                backgroundColor: props.theme.palette.warning.main,
                backgroundColorOnHover: '#EE8A00',
                backgroundColorOnFocus: props.theme.palette.warning.main,
                backgroundColorOnLoading: props.theme.palette.warning.main,
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: '0px 0px 0px 3px rgba(255, 178, 36, 0.20)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(255, 178, 36, 0.20)',
                textDecorationOnActive: undefined,
            },
            secondary: {
                color: '#fff',
                backgroundColor: '#ABB4BB',
                backgroundColorOnHover: '#7E868C',
                backgroundColorOnFocus: '#D6DCE0',
                backgroundColorOnLoading: '#D6DCE0',
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: '0px 0px 0px 3px rgba(136, 144, 150, 0.10)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(136, 144, 150, 0.10)',
                textDecorationOnActive: undefined,
            },
        },
        outline: {
            primary: {
                color: props.theme.palette.primary.main,
                backgroundColor: '#fff',
                backgroundColorOnHover: '#F8FAFF',
                backgroundColorOnFocus: '#fff',
                backgroundColorOnLoading: '#fff',
                border: '1px solid #97AFEF',
                borderColorOnHover: props.theme.palette.primary.main,
                boxShadowOnActive: `0px 0px 0px 3px ${props.theme.customColors.button.activeShadowPrimary}`,
                boxShadowOnFocus: `0px 0px 0px 3px ${props.theme.customColors.button.activeShadowPrimary}`,
                textDecorationOnActive: undefined,
            },
            'primary-light': {
                color: props.theme.palette.primary.main,
                backgroundColor: '#fff',
                backgroundColorOnHover: '#F8FAFF',
                backgroundColorOnFocus: '#fff',
                backgroundColorOnLoading: '#fff',
                border: '1px solid #97AFEF',
                borderColorOnHover: props.theme.palette.primary.main,
                boxShadowOnActive: `0px 0px 0px 3px ${props.theme.customColors.button.activeShadowPrimary}`,
                boxShadowOnFocus: `0px 0px 0px 3px ${props.theme.customColors.button.activeShadowPrimary}`,
                textDecorationOnActive: undefined,
            },
            error: {
                color: props.theme.palette.error.main,
                backgroundColor: '#FFF',
                backgroundColorOnHover: '#FFF8F8',
                backgroundColorOnFocus: '#fff',
                backgroundColorOnLoading: '#fff',
                border: '1px solid #F9C6C6',
                borderColorOnHover: '#F9C6C6',
                boxShadowOnActive: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
                textDecorationOnActive: undefined,
            },
            success: {
                color: props.theme.palette.success.main,
                backgroundColor: '#FFF',
                backgroundColorOnHover: '#F2FCF5',
                backgroundColorOnFocus: '#fff',
                backgroundColorOnLoading: '#FFF',
                border: '1px solid #9ED9B3',
                borderColorOnHover: '#9ED9B3',
                boxShadowOnActive: '0px 0px 0px 3px rgba(48, 164, 108, 0.20)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(48, 164, 108, 0.20)',
                textDecorationOnActive: undefined,
            },
            warning: {
                color: props.theme.palette.warning.main,
                backgroundColor: '#FFF',
                backgroundColorOnHover: '#FFF9ED',
                backgroundColorOnFocus: '#fff',
                backgroundColorOnLoading: '#FFF',
                border: '1px solid #FFA01C',
                borderColorOnHover: '#FFA01C',
                boxShadowOnActive: '0px 0px 0px 3px rgba(255, 178, 36, 0.20)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(255, 178, 36, 0.20)',
                textDecorationOnActive: undefined,
            },
            secondary: {
                color: '#687076',
                backgroundColor: '#FFF',
                backgroundColorOnHover: '#F8F9FA',
                backgroundColorOnFocus: '#F8F9FA',
                backgroundColorOnLoading: '#FFF',
                border: '1px solid #EFF1F3',
                borderColorOnHover: '#C1C8CD',
                boxShadowOnActive: '0px 0px 0px 3px rgba(136, 144, 150, 0.10)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(136, 144, 150, 0.10)',
                textDecorationOnActive: undefined,
            },
        },
        tinted: {
            primary: {
                color: props.theme.palette.primary.main,
                backgroundColor: '#E6EDFE',
                backgroundColorOnHover: '#C6D4F9',
                backgroundColorOnFocus: '#fff',
                backgroundColorOnLoading: '#C6D4F9',
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: `0px 0px 0px 3px ${props.theme.customColors.button.activeShadowPrimary}`,
                boxShadowOnFocus: `0px 0px 0px 3px ${props.theme.customColors.button.activeShadowPrimary}`,
                textDecorationOnActive: undefined,
            },
            'primary-light': {
                color: props.theme.palette.primary.main,
                backgroundColor: '#F8FAFF',
                backgroundColorOnHover: '#E6EDFE',
                backgroundColorOnFocus: '#F8FAFF',
                backgroundColorOnLoading: '#E6EDFE',
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: `0px 0px 0px 3px ${props.theme.customColors.button.activeShadowPrimary}`,
                boxShadowOnFocus: `0px 0px 0px 3px ${props.theme.customColors.button.activeShadowPrimary}`,
                textDecorationOnActive: undefined,
            },
            error: {
                color: props.theme.palette.error.main,
                backgroundColor: '#FFEFEF',
                backgroundColorOnHover: '#FFEFEF',
                backgroundColorOnFocus: '#fff',
                backgroundColorOnLoading: '#FFEFEF',
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
                textDecorationOnActive: undefined,
            },
            success: {
                color: props.theme.palette.success.main,
                backgroundColor: '#D6F2DF',
                backgroundColorOnHover: '#9ED9B3',
                backgroundColorOnFocus: '#fff',
                backgroundColorOnLoading: '#D6F2DF',
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: '0px 0px 0px 3px rgba(48, 164, 108, 0.20)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(48, 164, 108, 0.20)',
                textDecorationOnActive: undefined,
            },
            warning: {
                color: props.theme.palette.warning.main,
                backgroundColor: '#FFF4D5',
                backgroundColorOnHover: '#FFF9ED',
                backgroundColorOnFocus: '#fff',
                backgroundColorOnLoading: '#FFF4D5',
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: '0px 0px 0px 3px rgba(255, 178, 36, 0.20)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(255, 178, 36, 0.20)',
                textDecorationOnActive: undefined,
            },
            secondary: {
                color: '#687076',
                backgroundColor: '#EFF1F3',
                backgroundColorOnHover: '#EFF1F3',
                backgroundColorOnFocus: undefined,
                backgroundColorOnLoading: '#EFF1F3',
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: '0px 0px 0px 3px rgba(136, 144, 150, 0.10)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(136, 144, 150, 0.10)',
                textDecorationOnActive: undefined,
            },
        },
        plain: {
            primary: {
                color: props.theme.palette.primary.main,
                backgroundColor: 'transparent',
                backgroundColorOnHover: undefined,
                backgroundColorOnFocus: '#fff',
                backgroundColorOnLoading: 'transparent',
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: undefined,
                boxShadowOnFocus: undefined,
                textDecorationOnActive: 'underline',
            },
            'primary-light': {
                color: props.theme.palette.primary.main,
                backgroundColor: 'transparent',
                backgroundColorOnHover: undefined,
                backgroundColorOnFocus: '#fff',
                backgroundColorOnLoading: 'transparent',
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: undefined,
                boxShadowOnFocus: undefined,
                textDecorationOnActive: 'underline',
            },
            error: {
                color: props.theme.palette.error.main,
                backgroundColor: 'transparent',
                backgroundColorOnHover: '#FFEFEF',
                backgroundColorOnFocus: '#fff',
                backgroundColorOnLoading: 'transparent',
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
                textDecorationOnActive: undefined,
            },
            success: {
                color: props.theme.palette.success.main,
                backgroundColor: 'transparent',
                backgroundColorOnHover: '#D6F2DF',
                backgroundColorOnFocus: '#fff',
                backgroundColorOnLoading: 'transparent',
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: '0px 0px 0px 3px rgba(48, 164, 108, 0.20)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(48, 164, 108, 0.20)',
                textDecorationOnActive: undefined,
            },
            warning: {
                color: props.theme.palette.warning.main,
                backgroundColor: 'transparent',
                backgroundColorOnHover: '#FFF9ED',
                backgroundColorOnFocus: '#fff',
                backgroundColorOnLoading: 'transparent',
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: '0px 0px 0px 3px rgba(255, 178, 36, 0.20)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(255, 178, 36, 0.20)',
                textDecorationOnActive: undefined,
            },
            secondary: {
                color: '#687076',
                backgroundColor: 'transparent',
                backgroundColorOnHover: '#EFF1F3',
                backgroundColorOnFocus: '#F8F9FA',
                backgroundColorOnLoading: 'transparent',
                border: undefined,
                borderColorOnHover: undefined,
                boxShadowOnActive: '0px 0px 0px 3px rgba(136, 144, 150, 0.10)',
                boxShadowOnFocus: '0px 0px 0px 3px rgba(136, 144, 150, 0.10)',
                textDecorationOnActive: undefined,
            },
        },
    },
});
export default getButtonStyles;
